import React from 'react';
import ReactDOM from 'react-dom';
import { Router, browserHistory } from 'react-router';
import { render } from 'react-snapshot';

import './styles.less';

import routes from './routes';

render(<Router history={browserHistory} routes={routes} />, document.getElementById('app'));
