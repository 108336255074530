import * as React from 'react';
import { Col, Row } from 'react-bootstrap';

import ContactForm from '../components/ContactForm';

import Logo from '../images/limbr-logo.png';

export default class Landing extends React.Component {
  render() {
    return (
      <Row className="bg bg--overlay-top bg--overlay-left" style={{ paddingTop: '25px', minHeight: '100vh' }}>
        <Col xs={12} md={6} mdOffset={3}>
          <img id="logo" className="img-responsive center-block" src={Logo} alt="Limbr Logo" />
          <h1 className="text-center" style={{ paddingTop: '25px' }}>
            Hang tight!
          </h1>
          <p className="text-center lead">Our site will be launching soon, but we'd love to hear from you now:</p>
          <ContactForm />
          {/* Temporary hidden link so that react-snapshot crawls the 404 error page */}
          <a href="/404" style={{ display: 'none' }}>
            404
          </a>
        </Col>
      </Row>
    );
  }
}
