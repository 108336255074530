import * as React from 'react';
import { Link } from 'react-router';
import { Col, Row } from 'react-bootstrap';

import Logo from '../images/limbr-logo.png';

export default class Error extends React.Component {
  static displayName = 'Error';

  render() {
    return (
      <Row className="bg bg--overlay-top bg--overlay-left" style={{ paddingTop: '25px', minHeight: '100vh' }}>
        <Col xs={12} md={6} mdOffset={3}>
          <img id="logo" className="img-responsive center-block" src={Logo} alt="Limbr Logo" />
          <h1 className="text-center">How did you even get here?!</h1>
          <p className="text-center lead">
            You should probably go <Link to="/">home</Link>.
          </p>
        </Col>
      </Row>
    );
  }
}
