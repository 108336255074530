import * as React from 'react';
import { Route, IndexRoute } from 'react-router';

import Landing from './Landing';
import NotFound from './NotFound';

export default (
  <Route path="/">
    <IndexRoute component={Landing} />
    <Route path="*" component={NotFound} />
  </Route>
);
