import * as React from 'react';
import { Button, Col, ControlLabel, Form, FormControl, FormGroup, HelpBlock } from 'react-bootstrap';
import validate from 'validate.js';

const validateForm = (values) =>
  validate(values, {
    name: {
      presence: {
        allowEmpty: false,
        message: `^You must enter your name.`,
      },
    },
    email: {
      presence: {
        allowEmpty: false,
        message: `^You must enter your email address.`,
      },
      email: {
        message: `"%{value}" doesn't look like a valid email address.`,
      },
    },
    company: {
      presence: {
        allowEmpty: false,
        message: `^You must enter your company name.`,
      },
    },
    message: {
      presence: {
        allowEmpty: false,
        message: `^You must enter a message`,
      },
    },
  });

export default class ContactFormSmall extends React.Component {
  state = {
    submitSuccess: false,
    submitFail: false,
    submitFailError: '',
    values: {
      name: '',
      email: '',
      company: '',
      message: '',
    },
    validation: {
      isValid: false,
      errors: {
        name: null,
        email: null,
        company: null,
        message: null,
      },
    },
  };

  handleBlur(e) {
    const { name } = e.target;
    const { validation, values } = this.state;

    const errors = validateForm(values);

    if (errors && errors[name]) {
      this.setState({
        validation: {
          isValid: !errors,
          errors: {
            ...validation.errors,
            [name]: errors[name],
          },
        },
      });
    } else {
      this.setState({
        validation: {
          isValid: !errors,
          errors: {
            ...validation.errors,
            [name]: null,
          },
        },
      });
    }
  }

  handleChange(e) {
    const { name, value } = e.target;
    const { values } = this.state;
    this.setState({
      values: {
        ...values,
        [name]: value,
      },
    });
  }

  async handleSubmit(e) {
    e.preventDefault();
    const { values } = this.state;

    const errors = validateForm(values);

    if (errors) {
      this.setState({
        validation: {
          isValid: !errors,
          errors,
        },
      });
      return false;
    }

    const formData = new FormData(e.target);

    try {
      await fetch('/submit-form', {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: new URLSearchParams(formData).toString(),
      });
      this.setState({ submitSuccess: true });
    } catch (error) {
      this.setState({
        submitFail: true,
        submitFailError: `Oops! Something went wrong. (Error: ${error})`,
      });
    }
    return true;
  }

  render() {
    const { submitFail, submitFailError, submitSuccess, validation, values } = this.state;
    const { errors, isValid } = validation;

    return (
      <div>
        {submitSuccess && (
          <div className="alert alert-success" role="alert">
            <span>Thanks! We'll get back to you soon.</span>
          </div>
        )}
        {submitFail && (
          <div className="alert alert-danger" role="alert">
            <span>Oops! Something went wrong (Error: {submitFailError}).</span>
          </div>
        )}
        <Form data-netlify={true} id="contact-form" horizontal name="contact" onSubmit={(e) => this.handleSubmit(e)}>
          <input type="hidden" name="form-name" value="contact" />
          <fieldset disabled={submitSuccess}>
            <FormGroup controlId="name" validationState={errors && errors.name ? 'error' : null}>
              <Col xs={3}>
                <ControlLabel>Name*</ControlLabel>
              </Col>
              <Col xs={9}>
                <FormControl
                  name="name"
                  type="text"
                  value={values.name}
                  required
                  onBlur={(e) => this.handleBlur(e)}
                  onChange={(e) => this.handleChange(e)}
                />
                <FormControl.Feedback />
                {errors && errors.name && <HelpBlock>{errors.name.map((error) => error).join('. ')}</HelpBlock>}
              </Col>
            </FormGroup>
            <FormGroup controlId="email" validationState={errors && errors.email ? 'error' : null}>
              <Col xs={3}>
                <ControlLabel>Email*</ControlLabel>
              </Col>
              <Col xs={9}>
                <FormControl
                  name="email"
                  type="text"
                  value={values.email}
                  required
                  onBlur={(e) => this.handleBlur(e)}
                  onChange={(e) => this.handleChange(e)}
                />
                <FormControl.Feedback />
                {errors && errors.email && <HelpBlock>{errors.email.map((error) => error).join('. ')}</HelpBlock>}
              </Col>
            </FormGroup>
            <FormGroup controlId="company" validationState={errors && errors.company ? 'error' : null}>
              <Col xs={3}>
                <ControlLabel>Company*</ControlLabel>
              </Col>
              <Col xs={9}>
                <FormControl
                  name="company"
                  type="text"
                  value={values.company}
                  required
                  onBlur={(e) => this.handleBlur(e)}
                  onChange={(e) => this.handleChange(e)}
                />
                <FormControl.Feedback />
                {errors && errors.company && <HelpBlock>{errors.company.map((error) => error).join('. ')}</HelpBlock>}
              </Col>
            </FormGroup>
            <FormGroup controlId="message" validationState={errors && errors.message ? 'error' : null}>
              <Col xs={3}>
                <ControlLabel>Message*</ControlLabel>
              </Col>
              <Col xs={9}>
                <FormControl
                  name="message"
                  componentClass="textarea"
                  type="textarea"
                  rows="3"
                  value={values.message}
                  required
                  onBlur={(e) => this.handleBlur(e)}
                  onChange={(e) => this.handleChange(e)}
                />
                <FormControl.Feedback />
                {errors && errors.message && <HelpBlock>{errors.message.map((error) => error).join('. ')}</HelpBlock>}
              </Col>
            </FormGroup>
            <FormGroup>
              <Col xs={9} xsOffset={3}>
                <Button bsStyle="primary" type="submit">
                  Let's talk!
                </Button>
              </Col>
            </FormGroup>
          </fieldset>
        </Form>
      </div>
    );
  }
}
